import centralBankLicense1 from '../../../assets/files/en/license_en.pdf';
import centralBankLicense2 from '../../../assets/files/en/License_of_the_Central_Bank_of_the_Russian_Federation_No3538_dated_May_16_2024.pdf';
import centralBankLicense3 from '../../../assets/files/en/License_of_the_Central_Bank_of_the_Russian_Federation_No3538_dated_November_29_2024.pdf';

export default {
    seo: {
        title: 'Central Bank license | Bank 131',
        description: 'Our bank details'
    },
    title: 'Central Bank license',
    content: [
        {
            label: 'Posting date 12/17/2024',
            title: 'License of the Central Bank of the Russian Federation No. 3538 dated November 29, 2024.PDF',
            link: centralBankLicense3
        }
    ],
    voidedContentTitle: 'Voided',
    voidedContent: [
        {
            label: 'Posting date 05/23/2024',
            title: 'License of the Central Bank of the Russian Federation No. 3538 dated May 16, 2024.PDF',
            link: centralBankLicense2
        },
        {
            label: 'Posting date 08/09/2019',
            title: 'License of the Central Bank of the Russian Federation No. 3538 dated April 12, 2019.PDF',
            link: centralBankLicense1
        },
        {
            label: 'Posting date 01/26/2021',
            title: 'QR License of the Central Bank of the Russian Federation No 3538.pdf',
            link: '/legal/en/QR_License_of_the_Central_Bank_of_the_Russian_Federation_No_3538.pdf'
        }
    ]
}