import centralBankLicense1 from '../../../assets/files/ru/Лицензия_Банка_России_№3538_от_12 апреля.pdf';
import centralBankLicense2 from '../../../assets/files/ru/Лицензия_Банка_России_№3538_от_16_мая_2024.pdf';
import centralBankLicense3 from '../../../assets/files/ru/Лицензия_Банка_России_№3538_от_29_ноября_2024.pdf';

export default {
    seo: {
        title: 'Лицензия Банка России | Банк 131',
        description: 'Реквизиты АО «Банк 131»'
    },
    title: 'Лицензия Банка России',
    content: [
        {
            label: 'Дата размещения 17.12.2024',
            title: 'Лицензия Банка России № 3538 от 29 ноября 2024.PDF',
            link: centralBankLicense3
        },
    ],
    voidedContentTitle: 'Утратили силу',
    voidedContent: [
        {
            label: 'Дата размещения 23.05.2024',
            title: 'Лицензия Банка России № 3538 от 16 мая 2024.PDF',
            link: centralBankLicense2
        },
        {
            label: 'Дата размещения 09.08.2019',
            title: 'Лицензия Банка России № 3538 от 12 апреля 2019.PDF',
            link: centralBankLicense1
        }
    ]
}